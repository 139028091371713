html {
  position: relative;
  min-height: 100%;
}

body {
  /* 
  overflow: hidden; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /* width: 100%;
  height: 100%;
  background-color: #130b0b;
  overflow: hidden; */
}
